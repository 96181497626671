<template>
    <div :class="`${prefixCls}-patent`" id="aochenPatent">
        <Separate title="研发专利" desc="R&D PATENT" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
        <div :class="`${prefixCls}-rd-new-content`">
            <custom-card class="card" v-for="item in honorList" :key="item.id" :value="item" @click="handleClick" />
        </div>
    </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import CustomCard from '@/components/CustomCard/index.vue'
import { siteNewsPage } from '@/api/index'
export default {
  name: 'QualificationsHonors',
  data () {
    return {
      prefixCls: this.$prefix + '-press',
      honorList: []
    }
  },
  mixins: [WowMixin],
  components: {
    CustomCard,
    Separate
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    handleClick (data) {
      this.$router.push({ path: '/detail', query: { id: data.id } })
    },
    siteNewsPage () {
      siteNewsPage({ channelId: '34' }, { channelId: 0 }).then(({ data }) => {
        this.honorList = data.data.rows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -press-patent;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $content-width;
  flex-grow: 1;
  &-content{
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    .card {
      margin: 0 20px 20px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
